//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';
import { fromUnixTime, getYear, getMonth, format } from 'date-fns';
import { useVenue, venueGetters } from '@odyssey/realtainment';
import { PRODUCT_ONLINE_EVENT_TYPE, isProductDisabled } from '@/helpers';
import de from 'date-fns/locale/de';
export default {
  components: {
    Spinner: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/Spinner'),
    Chip: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/Chip'),
    HandIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--hand.svg?inline'),
    VideoCameraIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--video-camera.svg?inline')
  },
  props: {
    events: {
      type: Array,
      default: () => []
    },
    city: {
      type: Object,
      default: () => null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { result: venues } = useVenue();

    const eventsByMonth = computed(() => {
      const reducedEvents = props.events.reduce((acc, curr) => {
        if (curr.eventTimestamp) {
          const date = fromUnixTime(curr.eventTimestamp);
          const year = getYear(date);
          const month = getMonth(date);
          if (!acc[year]) {
            acc[year] = {};
          }

          if (!Array.isArray(acc[year][month]?.events)) {
            acc[year][month] = {
              title: format(date, 'LLLL', { locale: de }),
              events: []
            };
          }

          curr.venue = venues.value.find((v) => v.uuid === curr.venueUuid);
          curr.event_date = {
            ...curr.event_date,
            month_short: format(date, 'LLL', { locale: de }),
            day_short: format(date, 'EEEEEE', { locale: de }),
            day_num: format(date, 'd', { locale: de })
          };

          acc[year][month].events.push(curr);
          acc[year][month].events = acc[year][month].events.sort((a, b) => {
            return parseInt(a.event_date.day_num) - parseInt(b.event_date.day_num);
          });
        }

        return acc;
      }, {});

      return reducedEvents;
      // return Object.fromEntries(Object.entries(reducedEvents).sort());
    });
    return {
      eventsByMonth,
      venues,
      venueGetters,
      PRODUCT_ONLINE_EVENT_TYPE,
      isProductDisabled
    };
  }
};
