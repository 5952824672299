var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"date-selector"},[(_vm.loading)?_c('spinner'):(_vm.eventsByMonth && Object.keys(_vm.eventsByMonth).length)?_vm._l((_vm.eventsByMonth),function(yearData,year){return _vm._l((yearData),function(monthData,month){return _c('section',{key:("" + month + year),staticClass:"date-selector__group"},[_c('header',{staticClass:"date-selector__group-header"},[_vm._v(_vm._s(monthData.title)+" "+_vm._s(year))]),_vm._v(" "),_c('ol',{staticClass:"date-selector__group-list"},_vm._l((monthData.events),function(eventData,eventIndex){return _c('li',{key:eventIndex},[_c('nuxt-link',{staticClass:"date-list-item",attrs:{"to":_vm.localePath({
                name: 'product-event',
                params: {
                  slug: eventData.slug
                }
              })}},[_c('div',{staticClass:"date-list-item__date"},[_c('div',{staticClass:"date-list-item__day"},[_vm._v(_vm._s(eventData.event_date.day_num))]),_vm._v(" "),_c('div',{staticClass:"date-list-item__month"},[_vm._v(_vm._s(eventData.event_date.month_short)+" "+_vm._s(year))])]),_vm._v(" "),_c('div',{staticClass:"date-list-item__time"},[_vm._v("\n              "+_vm._s(eventData.event_date.day_short)+", "+_vm._s(eventData.event_date.start_time)+" –\n              "+_vm._s(eventData.event_date.end_time)+" Uhr\n            ")]),_vm._v(" "),(eventData.venue)?_c('div',{staticClass:"date-list-item__venue"},[_vm._v("\n              "+_vm._s(_vm.venueGetters.getName(eventData.venue))+"\n            ")]):_vm._e(),_vm._v(" "),(eventData.venue)?_c('div',{staticClass:"date-list-item__address"},[_vm._v("\n              "+_vm._s(_vm.venueGetters.getAddress(eventData.venue))+"\n            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"date-list-item__tags"},[(eventData.typeId === _vm.PRODUCT_ONLINE_EVENT_TYPE)?_c('Chip',[_c('VideoCameraIcon'),_vm._v(" "),_c('span',[_vm._v("Online ArtNight")])],1):_vm._e(),_vm._v(" "),(_vm.isProductDisabled(eventData))?_c('Chip',{attrs:{"color":"#F6F6F6"}},[_c('HandIcon'),_vm._v(" "),_c('span',[_vm._v("Ausverkauft")])],1):(eventData.quantity <= 5)?_c('Chip',{attrs:{"color":"#FF440A1F","textColor":"#FF440A"}},[_c('span',[_vm._v("Wenige Spots verfügbar")])]):_vm._e()],1)])],1)}),0)])})}):_c('div',[_vm._v("\n    "+_vm._s(_vm.city
        ? _vm.$t('event.select_date_empty_state_with_city_text', {
            city: _vm.city.name
          })
        : _vm.$t('event.select_date_empty_state_text'))+"\n  ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }