import { render, staticRenderFns } from "./Select.vue?vue&type=template&id=a5785a6a&scoped=true"
import script from "./Select.vue?vue&type=script&lang=js"
export * from "./Select.vue?vue&type=script&lang=js"
import style0 from "./Select.vue?vue&type=style&index=0&id=a5785a6a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5785a6a",
  null
  
)

export default component.exports