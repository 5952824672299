//
//
//
//
//
//
//
//

import { computed, ref, useContext } from '@nuxtjs/composition-api';
import { useGlobalUI } from '@/composables/useGlobalUI';
import { formatGtm, useCategory, useGroupProduct } from '@odyssey/realtainment';
import { useOrderingFlow } from '@/composables/useGroupOrderFlow';

export default {
  components: {
    CityAndDateSelect: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-modals" */ '@/components/UI/molecules/selects/CityAndDateSelect')
  },
  setup() {
    const { $gtm, route } = useContext();
    const searchInput = ref(null);
    const { toggleGlobalUI, isGlobalUIActive, setGlobalUI } = useGlobalUI();
    const { categories } = useCategory();

    // If it is the group events booking flow, use cities from group product API call
    const { cities } = useGroupProduct();
    const { setFormData } = useOrderingFlow();

    const isPrivateBooking = computed(() => route?.value?.meta?.isPrivateBooking || false);

    const filteredCities = computed(() => {
      return searchInput.value === null || searchInput.value.length === ''
        ? cities.value
        : cities.value.filter((city) => city.categorySlug.toLowerCase().includes(searchInput.value.toLowerCase()));
    });

    const filteredCategories = computed(() => {
      if (searchInput.value === null || searchInput.value.length === '') {
        return categories.value.filter((category) => category.includeInMenu && category.productCount > 0);
      }

      return categories.value.filter(
        (category) =>
          category.includeInMenu &&
          category.productCount > 0 &&
          category.name.toLowerCase().includes(searchInput.value.toLowerCase())
      );
    });

    const onModalItemSelected = (categorySlug) => {
      if (isPrivateBooking.value) {
        setFormData({ locationCity: categorySlug }, 'eventInfo');
        const el = document.getElementById('GroupEventsOrder');
        if (el) el.scrollIntoView();
      }

      setGlobalUI('CityModal', false);
      setGlobalUI('SideNav', false);
      $gtm.push(
        formatGtm('listing/CITY_PANEL_INPUT_SUBMIT', {
          location: route.value.meta.pageType,
          selection: categorySlug
        })
      );
    };
    const onInputFocus = () => {
      $gtm.push(
        formatGtm('listing/CITY_PANEL_INPUT_FOCUS', {
          location: route.value.meta.pageType
        })
      );
    };

    return {
      isVisible: isGlobalUIActive('CityModal'),
      searchInput,
      filteredCategories,
      onModalItemSelected,
      toggleGlobalUI,
      onInputFocus,
      filteredCities,
      isPrivateBooking
    };
  }
};
